<template>
  <span>
    <confirm-modal
      id="previous-document-type-confirm"
      :v-if="confirmPreviousDocument"
      title="Are you sure you want to proceed?"
      :confirmation="prevDocTypeConfirmText"
      @submit="submit()"
    />
    <b-modal
      :id="this.$attrs.id"
      v-model="isVisible"
      title="Declaration information"
      size="lg"
    >
      <b-form>
        <validation-observer ref="formValidation">
          <b-form-group
            v-if="!clearance"
            label="Declaration type"
            label-for="arrived"
            label-class="mb-1"
          >
            <validation-provider
              #default="{ errors }"
              name="Declaration type"
              rules="required"
            >
              <b-form-radio-group
                id="arrived"
                v-model="hasArrived"
                :options="arrivedOptions"
                name="arrived"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Choose warehouse"
            label-for="vue-select"
          >
            <validation-provider
              #default="{ errors }"
              name="Warehouse"
              :rules="'required'"
            >
              <b-form-select
                id="warehouse"
                v-model="warehouse"
                :options="warehouses"
                :state="errors.length > 0 ? false : null"
              />
            </validation-provider>
          </b-form-group>
          <b-form-group
            v-if="shipment.entry_country === 'RO'"
            label="Previous document category"
            label-for="previous_document_category"
          >
            <validation-provider
              #default="{ errors }"
              name="Previous document category"
            >
              <b-form-select
                id="previous_document_category"
                v-model="previous_document_category"
                :options="previous_document_categories"
                placeholder="Choose document category"
                :state="errors.length > 0 ? false : null"
              />
            </validation-provider>
          </b-form-group>
          <b-form-group
            v-if="shipment.entry_country !== 'LT'"
            label="Previous document type"
            label-for="previous_document_type"
          >
            <validation-provider
              #default="{ errors }"
              name="Previous document type"
              :rules="shipment.entry_country !== 'RO' && declarationType === 'h1' ? 'required' : ''"
            >
              <b-form-select
                id="previous_document_type"
                v-model="previous_document_type"
                :options="previous_document_types"
                placeholder="Choose document type"
                :state="errors.length > 0 ? false : null"
              />
            </validation-provider>
          </b-form-group>
          <b-form-group
            v-if="shipment.entry_country !== 'LT'"
            label="Previous document reference"
            label-for="previous_document_reference"
          >
            <validation-provider
              #default="{ errors }"
              name="Previous document reference"
              :rules="shipment.entry_country !== 'RO' && declarationType === 'h1' ? 'required' : ''"
            >
              <b-form-input
                id="previous_document_reference"
                v-model="previous_document_reference"
                placeholder="Previous document reference"
                autocomplete="off"
                :state="errors.length > 0 ? false : null"
              />
            </validation-provider>
          </b-form-group>
          <b-form-group
              v-if="previousDocumentRequireNumberOfItemsMap[previous_document_type]"
              label="Previous document number of items"
              label-for="number_of_items"
          >
            <validation-provider
                #default="{ errors }"
                name="Previous document number of items"
                :rules="{ between: { min: 1, max: 99 }}"
            >
              <sup class="text-danger">{{ errors[0] }}</sup>
              <b-form-input
                  id="previous_document_number_of_items"
                  v-model.number="previous_document_number_of_items"
                  placeholder="Previous document number of items"
                  autocomplete="off"
                  :state="errors.length > 0 ? false : null"
              />
            </validation-provider>
          </b-form-group>
          <b-form-group
            v-if="declarationType === 'h1'"
            label="Inland mode of transport"
            label-for="inland_mode_of_transport"
          >
            <validation-provider
              #default="{ errors }"
              name="Inland mode of transport"
              :rules="'required'"
            >
              <b-form-select
                id="inland_mode_of_transport"
                v-model="inland_mode_of_transport"
                :options="transport_types"
                :state="errors.length > 0 ? false : null"
              />
            </validation-provider>
          </b-form-group>
          <b-form-group
            v-if="declarationType === 'h1'"
            label="Mode of transport at the border"
            label-for="mode_of_transport_at_border"
          >
            <validation-provider
              #default="{ errors }"
              name="Mode of transport at the border"
              :rules="'required'"
            >
              <b-form-select
                id="mode_of_transport_at_border"
                v-model="mode_of_transport_at_border"
                :options="transport_types"
                :state="errors.length > 0 ? false : null"
              />
            </validation-provider>
          </b-form-group>
          <b-form-group
            v-if="mode_of_transport_at_border"
            label="Transport registration number"
            label-for="transport_registration_number"
          >
            <validation-provider
              #default="{ errors }"
              name="Transport registration number"
              :rules="'max:35'"
            >
              <b-form-input
                id="transport_registration_number"
                v-model="transport_registration_number"
                placeholder="Transport registration number"
                autocomplete="off"
                :state="errors.length > 0 ? false : null"
              />
            </validation-provider>
          </b-form-group>
          <div
            v-if="showNotArrivedOption && warehouse && !resendables"
            class="form-group"
          >
            <label
              for="send_arrived_to_facility"
              class="text-danger"
            >
              Some of selected containers/parcels are missing "Arrived to facility" event
            </label>
            <b-form-checkbox
              id="send_arrived_to_facility"
              v-model="send_arrived_to_facility"
              name="send_arrived_to_facility"
              value="true"
              unchecked-value="false"
            >
              Send "Arrived to facility" event
            </b-form-checkbox>
          </div>
        </validation-observer>
      </b-form>

      <template #modal-footer="">
        <b-button
          variant="primary"
          :disabled="!shipment.entry_country"
          @click="validateSend()"
        >
          Submit declarations
        </b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>

import {
  BButton,
  BForm,
  BFormGroup,
  BFormRadioGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BModal,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import handleError from '@/views/components/errorHandler'
import ConfirmModal from '@core/components/confirmation/confirmModal.vue'

export default {
  components: {
    ConfirmModal,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BModal,
  },
  props: {
    clearance: {
      type: Boolean,
      default: false,
    },
    resendables: {
      type: Boolean,
      default: false,
    },
    shipment: {
      type: Object,
      required: true,
    },
    selectedParcels: {
      type: Array,
      required: true,
    },
    selectedContainers: {
      type: Array,
      required: true,
    },
    selectedAll: {
      type: Boolean,
      default: false,
    },
    declarationType: {
      type: String,
      default: 'h7',
    },
  },
  data() {
    return {
      arrivedOptions: [
        { text: 'A - Regular', value: true },
        { text: 'D - Pre-arrival', value: false },
      ],
      showNotArrivedOption: false,
      isVisible: false,
      confirmPreviousDocument: false,
      prevDocTypeConfirmText: '',
      hasArrived: null,
      warehouse: '',
      warehouses: [{ value: '', text: '----select----' }],
      previous_document_category: '',
      previous_document_type: null,
      previous_document_number_of_items: null,
      previous_document_types: [],
      previous_document_categories: [
        { value: '', text: '----select----' },
        { value: 'X', text: 'Summary Declaration' },
        { value: 'Y', text: 'Initial Declaration' },
        { value: 'Z', text: 'Previous Document' },
      ],
      previous_document_reference: '',
      inland_mode_of_transport: this.shipment?.company?.config?.defaultTransportMode ?? '',
      mode_of_transport_at_border: this.shipment?.company?.config?.defaultTransportMode ?? '',
      transport_registration_number: this.shipment?.company?.config?.defaultTransportRegistrationNumber ?? '',
      transport_types: this.$classifiers().getOptions('transport_types'),
      send_arrived_to_facility: this.shipment.company.config?.arrivedToFacilityEventSelected ?? false,
      previousDocumentRequireNumberOfItemsMap: {},
    }
  },
  watch: {
    selectedAll() {
      this.handleSendH7Declarations()
    },
    selectedContainers() {
      this.handleSendH7Declarations()
    },
    selectedParcels() {
      this.handleSendH7Declarations()
    },
    previous_document_types(values) {
      values.forEach(previousDocumentType => {
        this.previousDocumentRequireNumberOfItemsMap[previousDocumentType.value] = previousDocumentType.require_number_of_items
      })
    },
  },
  created() {
    this.$http.get(`/v1/companies/${this.shipment.company_id}/warehouses`)
      .then(response => {
        const warehouses = response.data.data.filter(x => x.country === this.shipment.entry_country)
        const moreThan1Company = warehouses.length > 0
          ? (new Set(warehouses.map(warehouse => warehouse?.company?.id))).size > 1
          : false

        this.warehouses = this.warehouses.concat(warehouses.map(warehouse => ({
          value: warehouse.id,
          text: moreThan1Company ? `${warehouse.name} (${warehouse.company.name})` : warehouse.name,
        })))
      })
    this.getCustomsData()
    this.handleSendH7Declarations()
  },
  methods: {
    handleSendH7Declarations() {
      this.showNotArrivedOption = false
      this.$http.post(
        `/v1/companies/${this.shipment.company_id}/shipments/${this.shipment.id}/parcels-arrived`,
        this.getSelected(),
      )
        .then(response => {
          this.showNotArrivedOption = !!response.data.hasMissingEvent
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
    getSelected() {
      const ret = {
        all: null,
        containers: null,
        parcels: null,
      }
      if (this.selectedAll) {
        // send only all if it is selected
        ret.all = true
      } else {
        // send selected containers and separately selected parcels
        ret.containers = this.selectedContainers
        ret.parcels = this.selectedParcels
        if (ret.containers.length) {
          // remove parcels where container is selected
          const parcelIds = this.getSelectedContainersParcels()
          ret.parcels = ret.parcels.filter(x => !parcelIds.includes(x))
        }
      }
      return ret
    },
    getSelectedContainersParcels() {
      if (!this.selectedParcels || !this.selectedParcels.length || !Array.isArray(this.allParcelsData)) {
        return []
      }
      const containerCodesSet = new Set(this.selectedContainers)
      return (this.allParcelsData || [])
        .map(parcelGroup => Object.values(parcelGroup || {}).flat()
          .filter(parcel => parcel && containerCodesSet.has(parcel.container_code))
          .map(parcel => parcel.id))
        .flat()
    },
    validateSend() {
      this.$refs.formValidation.validate().then(success => {
        if (!success) {
          return
        }
        const selectedDocumentType = this.previous_document_types.find(x => x.value === this.previous_document_type)
        if (selectedDocumentType?.confirm) {
          this.confirmPreviousDocument = true
          this.$bvModal.show('previous-document-type-confirm')
          this.prevDocTypeConfirmText = `You selected "${selectedDocumentType.text}", are you sure you want to continue`
        } else {
          this.submit()
        }
      })
    },
    getCustomsData() {
      this.$http.get(`/v1/customs/previous-document-types/${this.shipment.entry_country}`)
        .then(response => {
          this.previous_document_types = [{ value: null, text: '----select----', confirm: false, require_number_of_items: false }]
          this.previous_document_types = this.previous_document_types.concat(response.data.data.map(previousDocumentType => ({ value: previousDocumentType.type, text: previousDocumentType.name, confirm: previousDocumentType.confirm, require_number_of_items: previousDocumentType.require_number_of_items })))
        })
    },
    submit() {
      this.$bvModal.hide('previous-document-type-confirm')
      this.$emit('submit', {
        previous_document_type: this.previous_document_type,
        previous_document_category: this.previous_document_category,
        previous_document_reference: this.previous_document_reference,
        send_arrived_to_facility: this.send_arrived_to_facility,
        warehouse_id: this.warehouse,
        has_arrived: this.hasArrived,
        inland_mode_of_transport: this.inland_mode_of_transport,
        mode_of_transport_at_border: this.mode_of_transport_at_border,
        transport_registration_number: this.transport_registration_number,
        previous_document_number_of_items: this.previous_document_number_of_items,
      })
      this.previous_document_type = null
      this.previous_document_category = ''
      this.previous_document_reference = null
      this.warehouse = ''
      this.isVisible = false
      this.previous_document_number_of_items = null
    },
  },
}
</script>

<style scoped>

</style>
