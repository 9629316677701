<template>
  <b-card
    v-if="parcel"
    title="Parcel data"
  >
    <b-row>
      <b-col
        lg="3"
        md="4"
      >
        <validation-provider
          #default="{ errors }"
          name="Tracking code"
          rules="required"
        >
          <label>Tracking code</label>
          <b-form-input
            v-model="parcel.tracking_number"
            :disabled="disabled"
            class="mr-0 ml-0"
            :state="errors.length > 0 ? false:null"
          />
        </validation-provider>
      </b-col>
      <b-col
        lg="3"
        md="4"
      >
        <validation-provider
          #default="{ errors }"
          name="Alternative tracking code"
        >
          <label>Alternative tracking code</label>
          <b-form-input
            v-model="parcel.house_parcel_tracking_number"
            :disabled="disabled"
            class="mr-0 ml-0"
            :state="errors.length > 0 ? false:null"
          />
        </validation-provider>
      </b-col>
      <b-col
        lg="3"
        md="4"
      >
        <label>Container code</label>
        <b-form-input
          v-model="parcel.container_code"
          :disabled="disabled"
          class="mr-0 ml-0"
        />
      </b-col>
      <b-col
        lg="3"
        md="4"
      >
        <label>TSD position</label>
        <b-form-input
          v-model="parcel.tsd_position"
          :disabled="disabled"
          type="number"
          class="mr-0 ml-0"
        />
      </b-col>
      <b-col
        v-if="['EE', 'GR', 'HU', 'LV', 'LT', 'RO', 'SK'].includes(parcel.shipment.entry_country)"
        lg="3"
        md="4"
      >
        <validation-provider
          #default="{ errors }"
          name="Transport Cost"
          :rules="parcel.declaration_type === 'H1' && parcel.transport_cost_currency ? 'required' : ''"
        >
          <label>Transport Cost</label>
          <b-form-input
            v-model="parcel.transport_cost"
            :disabled="disabled"
            type="number"
            class="mr-0 ml-0"
            :state="errors.length > 0 ? false:null"
          />
        </validation-provider>
      </b-col>
      <b-col
        v-if="['EE', 'GR', 'HU', 'LV', 'LT', 'RO', 'SK'].includes(parcel.shipment.entry_country)"
        lg="3"
        md="4"
      >
        <validation-provider
          #default="{ errors }"
          name="Transport Cost Currency"
          :rules="parcel.declaration_type === 'H1' && parcel.transport_cost ? 'required' : ''"
        >
          <label>Transport Cost Currency</label>
          <b-form-input
            v-model="parcel.transport_cost_currency"
            :disabled="disabled"
            class="mr-0 ml-0"
            :state="errors.length > 0 ? false:null"
          />
        </validation-provider>
      </b-col>
      <b-col
        v-if="['EE', 'LV', 'NL'].includes(parcel.shipment.entry_country)"
        lg="3"
        md="4"
      >
        <validation-provider
          #default="{ errors }"
          name="Packages Count"
          rules="required|minvalue:1"
        >
          <label>Packages Count</label>
          <b-form-input
            v-model="parcel.packages_count"
            :disabled="disabled"
            type="number"
            class="mr-0 ml-0"
            :state="errors.length > 0 ? false:null"
          />
        </validation-provider>
      </b-col>
      <b-col
        v-if="parcel.declaration_type === 'H1'"
        lg="3"
        md="4"
      >
        <label>Parcel gross weight</label>
        <b-form-input
          v-model="parcel.parcel_gross_weight"
          :disabled="disabled"
          type="number"
          class="mr-0 ml-0"
        />
      </b-col>
    </b-row>
    <b-row v-if="loading">
      <b-col class="text-center">
        <b-spinner label="Loading..." />
        <h6>
          Retrieving buyer data...
        </h6>
      </b-col>
    </b-row>
    <buyer-data-component
      :parcel="parcel"
      :disabled="disabled"
      :company-id="companyId"
      :shipment-id="shipmentId"
      :event-hub="eventHub"
      :loading="loading"
    />
    <seller-data-component
      :parcel="parcel"
      :disabled="disabled"
    />
    <h1-data-component
      v-if="parcel.declaration_type === 'H1'"
      :parcel="parcel"
      :disabled="disabled"
    />
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BCard, BSpinner,
} from 'bootstrap-vue'
import BuyerDataComponent from '@/views/shipments/declarations/modals/components/BuyerDataComponent.vue'
import SellerDataComponent from '@/views/shipments/declarations/modals/components/SellerDataComponent.vue'
import H1DataComponent from '@/views/shipments/declarations/modals/components/H1DataComponent.vue'
import { ValidationProvider } from 'vee-validate'
import Vue from 'vue'

export default {
  components: {
    BSpinner,
    ValidationProvider,
    H1DataComponent,
    BCard,
    BuyerDataComponent,
    SellerDataComponent,
    BRow,
    BCol,
    BFormInput,
  },
  props: {
    parcel: {
      type: Object,
      required: true,
      default: () => {},
    },
    disabled: {
      type: Boolean,
    },
    eventHub: {
      default: () => new Vue(),
    },
    declaration: {
      type: Object,
      default: () => {},
    },
    companyId: {
      type: Number,
      required: true,
    },
    shipmentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
    }
  },
  created() {
    this.eventHub.$on('update-buyer', this.handleUpdateBuyerData)
    this.eventHub.$on('buyer-data-loader', this.handleBuyerDataLoader)
  },
  beforeDestroy() {
    this.eventHub.$off('buyer-data-loader', this.handleBuyerDataLoader)
    this.eventHub.$off('update-buyer', this.handleUpdateBuyerData)
  },
  methods: {
    handleBuyerDataLoader(loader) {
      this.loading = loader
    },
    handleUpdateBuyerData(newBuyer) {
      this.parcel.buyer = newBuyer
    },
  },
}
</script>
