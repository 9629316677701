export default {
  downloadFile(response, blob, fallbackFileName = '') {
    const filename = response.headers['content-disposition']?.split('=')[1] || fallbackFileName
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(link.href)
  },
  triggerDownload(url) {
    const link = document.createElement('a')
    link.href = url
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  },
}
