<template>
  <div>

    <submit-declarations-modal
      v-if="showDeclarationModal"
      id="modal-submit-for-clearance"
      clearance
      :shipment="shipment"
      :selected-parcels="selectedParcels"
      :selected-containers="selectedContainers"
      :selected-all="selectedAll"
      :declaration-type="declarationType"
      @submit="submit"
    />

    <b-row>
      <b-col
        class="mt-2"
        sm="5"
        md="5"
        lg="4"
      >
        <b-card-text>
          Parcels that for that declaration is lodged to customs, waiting for arrival.
        </b-card-text>
      </b-col>
      <b-col class="text-right">
        <download-warehouse-excel-button
          :disabled="containers.length === 0"
          label="Download Excel for Warehouse"
          :shipment-id="shipment.id"
          type="waitingForArrival"
        />
        <b-button
          v-b-modal.modal-submit-for-clearance
          variant="outline-success"
          class="m-1"
          :disabled="disabledH7Btn"
          @click="showDeclarationModal = true"
          @close="showDeclarationModal = false"
        >
          Send presentation notices
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>

        <parcels-tree
          :event-hub="eventHub"
          :shipment="shipment"
          :containers="containers"
          filter="waitingForArrival"
          clearance="true"
          :data-loading="loading"
          @sortDesc="fetchData(null, $event)"
          @selection="selection"
          @update-filters="filters = $event"
        />

      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCardText,
  BCol,
  BRow,
} from 'bootstrap-vue'
import ParcelsTree from '@/views/shipments/declarations/ParcelsTree.vue'
import SubmitDeclarationsModal from '@/views/shipments/declarations/SubmitDeclarationsModal.vue'
import DownloadWarehouseExcelButton from '@/views/shipments/declarations/components/classes/DownloadWarehouseExcelButton.vue'
import Vue from 'vue'
import { searchQuery } from '@core/utils/filter'

export default {
  components: {
    BButton,
    BCardText,
    BCol,
    BRow,
    ParcelsTree,
    SubmitDeclarationsModal,
    DownloadWarehouseExcelButton,
  },
  props: {
    eventHub: {
      default: () => new Vue(),
    },
    filters: {
      type: Object,
      default: () => {},
    },
    shipment: {
      type: Object,
      required: true,
    },
    declarationType: {
      type: String,
      default: 'h7',
    },
  },
  data() {
    return {
      containers: [],
      selectedAll: false,
      showDeclarationModal: false,
      selectedContainers: [],
      selectedParcels: [],
      loading: false,
    }
  },
  computed: {
    disabledH7Btn() {
      return this.selectedAll === false && this.selectedContainers.length === 0 && this.selectedParcels.length === 0
    },
  },
  watch: {
    filters() {
      this.fetchData()
    },
    sortBy() {
      this.fetchData()
    },
    isSortDirDesc() {
      this.fetchData()
    },
  },
  created() {
    this.fetchData()
    this.eventHub.$on('general-declaration-update', this.fetchData)
    this.eventHub.$on('renewDeclarationList', this.handleRenewDeclarationList)
  },
  beforeDestroy() {
    this.eventHub.$off('general-declaration-update', this.fetchData)
    this.eventHub.$off('renewDeclarationList', this.handleRenewDeclarationList)
  },
  methods: {
    handleRenewDeclarationList() {
      this.fetchData()
      this.eventHub.$emit('refresh-statistics')
    },
    fetchData(activeTab, sortDesc = null) {
      if (!activeTab || activeTab === 'waiting-arrival') {
        this.loading = true
        this.$http.get(searchQuery(`/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipment.id}/declarations/waiting-for-arrival`, this.filters), {
          params: {
            sortBy: 'container',
            sortDesc: sortDesc,
          },
        })
          .then(response => {
            this.containers = response.data.data
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    selection(data) {
      this.selectedAll = data.selectedAll
      this.selectedContainers = data.selectedContainers
      this.selectedParcels = data.selectedParcels
    },
    submit(data) {
      this.$http.post(`/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipment.id}/declarations/arrivals`, {
        all: this.all,
        containers: this.selectedContainers,
        parcels: this.selectedParcels,
        previous_document_type: data.previous_document_type,
        previous_document_category: data.previous_document_category,
        previous_document_reference: data.previous_document_reference,
        warehouse_id: data.warehouse_id,
        customs_country: data.customs_country,
        previous_document_number_of_items: data.previous_document_number_of_items,
      })
        .then(() => {
          this.eventHub.$emit('refresh-statistics')
        })
    },
  },
}
</script>

<style scoped>

</style>
