<template>
  <app-collapse>
    <app-collapse-item
      v-if="questions && questions.length > 0 || declaration.can_send_customs_message"
      title="Customs communication"
      class="mb-1"
    >
      <app-collapse>
        <communication
          v-if="canSendMessages"
          :declaration="declaration"
          :shipment-id="shipmentId"
          :question="newQuestion"
          :event="event"
          :is-new="true"
          :parcel-id="parcelId"
        />
        <communication
          v-for="(question, index) in questionArray"
          :key="index"
          :question="question"
          :declaration="declaration"
          :shipment-id="shipmentId"
        />
      </app-collapse>
    </app-collapse-item>

  </app-collapse>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Communication from '@/views/shipments/declarations/components/Communication.vue'

export default {
  components: {
    AppCollapseItem,
    AppCollapse,
    Communication,
  },
  props: {
    shipmentId: {
      type: Number,
      required: true,
    },
    parcelId: {
      type: Number,
      required: true,
    },
    declaration: {
      type: Object,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    questions: {
      type: Array,
      default: () => [],
    },
    canSendMessages: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      questionArray: [],
      newQuestion: { files: [], comment: '', io: 'outgoing' },
    }
  },
  created() {
    this.questionArray = this.questions
  },
}
</script>
