<template>
  <b-button
    v-if="canEditPostClearance"
    v-b-modal.post-clearance-edit-modal
    type="button"
    class="parcel_view"
    variant="primary"
    size="sm"
    @click="viewParcel"
  >
    <b-icon-pencil />
  </b-button>
</template>

<script>
import {
  BButton, BIconPencil,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BIconPencil,
  },
  props: ['eventHub', 'declaration', 'parcel', 'company'],
  computed: {
    canEditPostClearance() {
      return this.company.country.toLowerCase() === 'lv'
        && this.declaration
        && ['cleared', 'cleared_manually'].includes(this.declaration.status)
    },
  },
  methods: {
    viewParcel() {
      this.eventHub.$emit('set-post-clearance-parcel', this.declaration, this.parcel)
    },
  },
}
</script>

<style scoped>
  svg {
    font-size: 1em;
  }
</style>
