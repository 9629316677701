import apiResponse from '@/libs/apiResponse'
import moment from 'moment/moment'

export default {
  data() {
    return {
      showExcelDownloadModal: false,
      excelLoading: false,
      confirmModalMessage: '',
    }
  },
  methods: {
    async handleExcelDownload() {
      this.showExcelDownloadModal = true
      this.excelLoading = true

      try {
        const response = await fetch(this.excelDownloadUrl, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            Accept: 'application/json',
          },
          credentials: 'include',
          redirect: 'manual',
        })

        if (response.status >= 300 && response.status < 400) {
          const redirectUrl = response.headers.get('Location')
          apiResponse.triggerDownload(redirectUrl)
          this.excelLoading = false
          return
        }

        if (response.type === 'opaqueredirect') {
          apiResponse.triggerDownload(this.excelDownloadUrl)
          this.excelLoading = false
          return
        }

        const contentType = response.headers.get('content-type')

        if (contentType && contentType.includes('application/json')) {
          const json = await response.json()
          this.confirmModalMessage = json.message
          this.excelLoading = false
          this.$bvModal.show('excel-download-modal')
        } else {
          const blob = await response.blob()
          const timestamp = moment().format('YYYY-MM-DD-HH-mm-ss')
          let filename = `excel-list-${timestamp}.xlsx`
          if (this.excelFileName) {
            filename = this.excelFileName
          }
          apiResponse.downloadFile(response, blob, filename)
          this.excelLoading = false
        }
      } catch (error) {
        this.excelLoading = false
      }
    },
  },
}
