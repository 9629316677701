<template>
  <!-- Buyer data -->
  <b-row v-if="buyer && localLoading === false">
    <b-col
      lg="3"
      md="4"
    >
      <validation-provider
        #default="{ errors }"
        name="Buyer name"
        rules="required"
      >
        <label>Buyer name</label>
        <b-form-input
          v-model="buyer.name"
          :disabled="disabled"
          class="mr-0 ml-0"
          :state="errors.length > 0 ? false:null"
        />
      </validation-provider>
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <validation-provider
        #default="{ errors }"
        name="Buyer registry number"
        :rules="parcel.declaration_type === 'H1' ? 'required' : ''"
      >
        <label>Buyer registry number</label>
        <b-form-input
          v-model="buyer.registry_number"
          :disabled="disabled"
          class="mr-0 ml-0"
          :state="errors.length > 0 ? false:null"
        />
      </validation-provider>
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <validation-provider
        #default="{ errors }"
        name="Buyer street number"
        rules="required"
      >
        <label>Buyer street number</label>
        <b-form-input
          v-model="buyer.street_and_number"
          :disabled="disabled"
          class="mr-0 ml-0"
          :state="errors.length > 0 ? false:null"
        />
      </validation-provider>
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <validation-provider
        #default="{ errors }"
        name="Buyer postal code"
        rules="required|max:20"
      >
        <label>Buyer postal code</label>
        <b-form-input
          v-model="buyer.postal_code"
          :disabled="disabled"
          class="mr-0 ml-0"
          :state="errors.length > 0 ? false:null"
        />
      </validation-provider>
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <validation-provider
        #default="{ errors }"
        name="Buyer city"
        rules="required"
      >
        <label>Buyer city</label>
        <b-form-input
          v-model="buyer.city"
          :disabled="disabled"
          class="mr-0 ml-0"
          :state="errors.length > 0 ? false:null"
        />
      </validation-provider>
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <label>Buyer region</label>
      <b-form-input
        v-model="buyer.region"
        :disabled="disabled"
        class="mr-0 ml-0"
      />
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <validation-provider
        #default="{ errors }"
        name="Buyer country"
        rules="required|min:2|max:2|alpha"
      >
        <label>Buyer country</label>
        <b-form-input
          v-model="buyer.country"
          :disabled="disabled"
          class="mr-0 ml-0"
          :state="errors.length > 0 ? false:null"
        />
      </validation-provider>
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <label>Buyer email</label>
      <b-form-input
        v-model="buyer.email"
        :disabled="disabled"
        class="mr-0 ml-0"
      />
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <label>Buyer phone</label>
      <b-form-input
        v-model="buyer.phone"
        :disabled="disabled"
        class="mr-0 ml-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BFormInput,
  },
  props: {
    parcel: {
      type: Object,
      required: true,
      default: () => {},
    },
    disabled: {
      type: Boolean,
    },
    companyId: {
      type: Number,
      required: true,
    },
    shipmentId: {
      type: Number,
      required: true,
    },
    eventHub: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      required,
      buyer: {},
      localLoading: this.loading,
    }
  },
  watch: {
    loading(newVal) {
      this.localLoading = newVal
    },
  },
  created() {
    this.fetchBuyerData()
  },
  methods: {
    fetchBuyerData() {
      this.$http.get(`/v1/companies/${this.companyId}/shipments/${this.shipmentId}/parcels/${this.parcel.id}/buyer-data`)
        .then(response => {
          this.buyer = response.data.buyer
          this.localLoading = false
          this.eventHub.$emit('update-buyer', this.buyer)
          this.eventHub.$emit('buyer-data-loader', false)
        })
    },
  },
}
</script>
