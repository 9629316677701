<template>
  <b-modal
    id="invalidate-declaration-modal"
    ref="invalidate-declaration-modal"
    title="Invalidate declaration"
    style="top: 10px"
  >
    <validation-observer ref="invalidateDeclarationForm">
      <b-row class="mt-1">
        <b-col class="code-selector">
          <b-form-group
            label="Code"
            label-for="code"
          >
            <validation-provider
              #default="{ errors }"
              name="Code"
              rules="required"
            >
              <v-select
                v-model="form.code"
                :options="codeOptions"
                :clearable="false"
                :state="errors.length > 0 ? false : null"
                input-id="code"
                :reduce="option => option.value"
                attach
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Invalidation reason"
            label-for="reason"
          >
            <validation-provider
              #default="{ errors }"
              name="Invalidation reason"
              rules="required|max:100"
            >
              <b-form-textarea
                id="reason"
                v-model="form.reason"
                name="reason"
                :state="errors.length > 0 ? false : null"
                class="mr-0 ml-0"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            v-if="isPostClearanceLV"
            label="Phone number"
            label-for="phone"
          >
            <validation-provider
              #default="{ errors }"
              name="Phone number"
              rules="min:5|max:15"
            >
              <b-form-input
                id="phone"
                v-model="form.phone"
                name="phone"
                :state="errors.length > 0 ? false : null"
                class="mr-0 ml-0"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            v-if="isPostClearanceLV"
            label="E-mail"
            label-for="email"
          >
            <validation-provider
              #default="{ errors }"
              name="E-mail"
              rules="email|max:35"
            >
              <b-form-input
                id="email"
                v-model="form.email"
                name="email"
                :state="errors.length > 0 ? false : null"
                class="mr-0 ml-0"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            v-if="isPostClearanceLV"
            label="Bank account number"
            label-for="bank_account"
          >
            <validation-provider
              #default="{ errors }"
              name="Bank account number"
              rules="alpha_num|max:35"
            >
              <b-form-input
                id="bank_account"
                v-model="form.bank_account"
                name="bank_account"
                :state="errors.length > 0 ? false : null"
                class="mr-0 ml-0"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-file
            v-if="isPostClearanceLV"
            id="files"
            ref="fileHandle"
            v-model="uploadFile"
            placeholder="Select a file"
            drop-placeholder="Drop file here..."
            class="b-custom-control-xl"
            :multiple="true"
            @input="submitFile()"
          />
        </b-col>
      </b-row>
      <b-row
        v-if="files && files.length > 0"
        class="mt-1"
      >
        <b-col>
          <label>Files</label><br>
          <b-table
            :fields="[{ key: 'name', label: 'Name' }, { key: 'actions', label: '' }]"
            :items="files"
            thead-class="d-none"
            class="files-table"
            show-empty
          >
            <template #cell(name)="data">
              <div
                class="d-flex align-items-center"
              >
                <b-link
                  :href="`${fileBaseUrl}/files/${data.item.id}`"
                >
                  {{ data.item.name }}
                </b-link>
              </div>
            </template>
            <template
              #cell(actions)="data"
              class="text-right"
            >
              <b-button
                :disabled="data.item.declarations > 0"
                variant="outline-danger"
                size="sm"
                @click="deleteFile(data.item.id)"
              >
                <feather-icon
                  v-if="deletingId !== data.item.id"
                  icon="TrashIcon"
                  class="d-inline"
                />
                <b-spinner
                  v-else
                  small
                />
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          This request initiates the cancellation of a customs declaration. The customs authority will review the submission and make the final determination on the cancellation.
        </b-col>
      </b-row>
    </validation-observer>

    <template #modal-footer="{ close }">
      <b-container fluid>
        <b-row>
          <b-col>
            <b-button
              @click="close"
            >
              Close
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button
              variant="danger"
              :disabled="loading"
              @click="validationForm"
            >
              <span v-if="!loading">Request invalidation</span>
              <b-spinner
                v-else
                small
              />
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>
<script>

import {
  BButton,
  BCol,
  BContainer,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BModal,
  BRow,
  BSpinner,
  BFormFile,
  BLink,
  BTable,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import handleError from '@/views/components/errorHandler'
import vSelect from 'vue-select'

export default {
  components: {
    BTable,
    BLink,
    BFormFile,
    vSelect,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
    BFormTextarea,
    BFormInput,
    BCol,
    BRow,
    BButton,
    BContainer,
    BModal,
    BSpinner,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    declaration: {
      type: Object,
      default: () => {},
    },
    containers: {
      type: Array,
      default: () => [],
    },
    parcels: {
      type: Array,
      default: () => [],
    },
    event: {
      type: Object,
      required: true,
    },
    shipmentId: {
      type: Number,
      required: true,
    },
    companyId: {
      type: Number,
      required: true,
    },
  },
  data() {
    let codeOptions = [
      { value: 'A-12', label: 'Goods placed under another customs procedure' },
      { value: 'D-12', label: 'Duplicate declaration' },
      { value: 'E-12', label: 'The placing of goods under a customs procedure is not possible due to special circumstances' },
      { value: 'K-15', label: 'Private person returns goods purchased through distance selling DA 148 3' },
      { value: 'N-12', label: 'The goods did not arrive' },
      { value: 'U-12', label: 'Union goods declared' },
      { value: 'V-12', label: 'Incorrect declarant / incorrect form of representation' },
      { value: 'V-14', label: 'Incorrect information declared' },
    ]
    if (this.declaration?.customs_country === 'SK') {
      codeOptions = [
        { value: '1', label: 'Goods are to be released into another customs regime or the release is no longer justified' },
        { value: '14', label: 'Goods mistakenly presented in more than one customs declaration' },
        { value: '23', label: 'Low-value consignment (up to €150) is returned after dismissal' },
        { value: '24', label: 'Goods of the Union were mistakenly submitted to the customs regime as goods that are not goods of the Union' },
        { value: '6', label: 'Goods were mistakenly released into the customs regime, in which a customs debt arises upon importation' },
        { value: '7', label: 'Goods were mistakenly released into the customs regime, on the basis of which a customs debt arose upon import' },
        { value: '15', label: 'Declaration of a decision issued in favor of the person concerned as void' },
      ]
    }
    return {
      form: {
        reason: '',
        code: '',
        phone: '',
        email: '',
        bank_account: '',
      },
      deletingId: null,
      fileBaseUrl: this.declaration
        ? `${process.env.VUE_APP_API_URL}/v1/companies/${this.companyId}/shipments/${this.shipmentId}/declarations/${this.declaration.id}`
        : '',
      files: [],
      uploadFile: null,
      upload: {
        isUploading: false,
        uploadProgress: 0,
      },
      loading: false,
      codeOptions: codeOptions,
    }
  },
  computed: {
    isPostClearanceLV() {
      return this.declaration && this.declaration.customs_country === 'LV' && ['cleared', 'cleared_manually'].includes(this.declaration.status)
    },
  },
  created() {
    if (this.declaration) {
      this.fetchData()
    }
  },
  methods: {
    deleteFile(id) {
      this.deletingId = id
      this.$http.delete(`${this.fileBaseUrl}/files/${id}`)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File successfully removed',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.deletingId = null
          this.files = this.files.filter(file => file.id !== id)
        })
        .catch(() => {
          this.deletingId = null
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to delete file',
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
    },
    fetchData() {
      this.$http.get(`${this.fileBaseUrl}/files?type=invalidation`)
        .then(response => {
          this.files = response.data.data
        })
    },
    submitFile() {
      const formData = new FormData()
      this.uploadFile.forEach((file, i) => {
        formData.append(`files[${i}]`, file)
      })
      this.upload.isUploading = true
      this.upload.uploadProgress = 0
      this.$http.post(`${this.fileBaseUrl}/files?type=invalidation`,
        formData,
        {
          onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            this.upload.uploadProgress = percentCompleted

            return percentCompleted
          }.bind(this),
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          this.upload.isUploading = false
          this.files = this.files.concat(response.data.files)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File successfully uploaded',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.upload.isUploading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to upload file',
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
    },
    validationForm() {
      this.$refs.invalidateDeclarationForm.validate().then(success => {
        if (!success) {
          return
        }
        this.cancelDeclaration()
      })
    },
    cancelDeclaration() {
      this.loading = true
      const request = this.declaration
        ? this.form
        : { ...this.form, containers: this.containers, parcels: this.parcels }
      const url = this.declaration
        ? `/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipmentId}/declarations/cancelled/${this.declaration.id}`
        : `/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipmentId}/declarations/cancelled`
      this.$http.post(url, request)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Declaration cancellation request has been submitted',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.event.$emit('renewDeclarationList')
          this.$emit('invalidated')
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
