<template>
  <div>
    <confirm-modal
      v-if="showExcelDownloadModal"
      id="excel-download-modal"
      title="Information"
      :confirmation="confirmModalMessage"
      ok-button="Close"
      close-button=""
      @submit="showExcelDownloadModal = false"
    />
    <confirm-modal
      id="post-clearance-edit-modal"
      title="Information"
      :confirmation="confirmPostClearanceMessage"
      :description="confirmPostClearanceDescription"
      confirmation-styles="padding-bottom: 5px"
      description-styles="font-style: italic;"
      ok-button="Confirm"
      close-button="Close"
      @submit="confirmPostClearance"
    />
    <edit-parcel-items
      v-if="selectedDeclaration"
      id="edit-parcel-items"
      :parcel="selectedParcel"
      :event-hub="eventHub"
      :declaration="selectedDeclaration"
      :shipment="shipment"
      :readonly="!canEdit"
    />
    <invalidation-modal
      v-if="showInvalidation"
      style="top: 100px"
      :containers="selectedContainers"
      :parcels="selectedParcels"
      :shipment-id="shipment.id"
      :company-id="shipment.company_id"
      :event="eventHub"
      @invalidated="declarationInvalidated()"
    />
    <b-row>
      <b-col
        class="mt-2"
        cols="4"
      >
        <b-card-text>
          All declaration procedures completed.
        </b-card-text>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        class="mt-1"
        cols="4"
      >
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="btn-radios-1"
            v-model="showType"
            :options="viewOptions"
            button-variant="outline-primary"
            :aria-describedby="ariaDescribedby"
            name="radios-btn-default"
            buttons
            @change="resetSelects"
          />
          <b-button
            v-if="showType === 'containers' && showInvalidation"
            v-b-modal.invalidate-declaration-modal
            variant="outline-danger invalidate-btn"
            class="m-1"
          >
            Invalidate selected
          </b-button>
        </b-form-group>
      </b-col>
      <b-col
        class="text-right"
        cols="8"
      >
        <b-button
          variant="outline-success"
          class="m-1"
          @click="handleExcelDownload"
        >
          Download as Excel
        </b-button>
        <download-warehouse-excel-button
          type="cleared"
          label="Download Excel for Warehouse"
          :disabled="declarations.length === 0"
          :shipment-id="shipment.id"
        />
        <b-button
          v-if="shipment.company.is_customs_invoice_allowed"
          v-b-tooltip.hover.top="'Download pdf for all customs invoices'"
          variant="outline-success"
          class="m-1"
          :href="invoicesDownloadUrl"
        >
          Download all customs invoices
        </b-button>
        <b-button
          v-if="canDownloadClearanceMessages()"
          variant="outline-success"
          class="m-1"
          :href="messagesDownloadUrl"
        >
          Download clearance messages
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="showType === 'parcels'">
      <b-col>
        <div class="overflow-x-scroll">
          <b-table
            :items="declarations"
            responsive
            :fields="fields"
            :busy="loading"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            no-local-sorting
            show-empty
            empty-text="No matching records found"
            class="mb-0"
            :tbody-tr-class="rowClass"
          >
            <template #head()="data">
              <span class="text-nowrap">
                {{ data.label }}
              </span>
            </template>

            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(actions)="data">
              <div class="d-flex align-items-center">
                <b-button-group>
                  <post-clearance-edit-button
                    v-if="canEditPostClearance"
                    :declaration="data.item"
                    :parcel="data.item.parcel"
                    :company="shipment.company"
                    :event-hub="eventHub"
                  />
                  <view-parcel-button
                    :declaration="data.item"
                    :parcel="data.item.parcel"
                    :event-hub="eventHub"
                  />
                  <download-pdf-button
                    :declaration="data.item"
                    :parcel="data.item.parcel"
                    :shipment="shipment"
                  />
                </b-button-group>
                <b-icon-archive
                  v-if="data.item.parcel.is_archived"
                  v-b-tooltip.hover.top="'Buyer personal data is removed'"
                  class="ml-1 cursor-pointer text-secondary"
                />
              </div>
            </template>

            <template #cell(tracking_number)="data">
              {{ data.item.parcel.tracking_number }}
            </template>

            <template #cell(container_code)="data">
              {{ data.item.parcel.container_code }}
            </template>

            <template #cell(updated_at)="data">
              {{ data.item.updated_at | formatDate }}
            </template>

          </b-table>
        </div>
        <pagination
          v-if="!loading && meta"
          :event-hub="eventHub"
          :per-page="perPage"
          :current-page="page"
          :total-rows="meta.total"
        />
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <parcels-tree
          :event-hub="eventHub"
          :shipment="shipment"
          :containers="containers"
          filter="cleared"
          clearance="true"
          :data-loading="loading"
          @sortDesc="fetchData()"
          @selection="selection"
          @update-filters="filters = $event"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCardText,
  BCol,
  BRow,
  BTable,
  BSpinner,
  VBTooltip,
  BButtonGroup,
  BIconArchive,
  BFormGroup,
  BFormRadioGroup,
} from 'bootstrap-vue'
import ParcelsTree from '@/views/shipments/declarations/ParcelsTree.vue'
import ViewParcelButton from '@/views/shipments/declarations/components/ViewParcelButton.vue'
import DownloadPdfButton from '@/views/shipments/declarations/components/DownloadPdfButton.vue'
import DownloadWarehouseExcelButton from '@/views/shipments/declarations/components/classes/DownloadWarehouseExcelButton.vue'
import Pagination from '@/layouts/components/Pagination/Pagination.vue'
import EditParcelItems from '@/views/shipments/declarations/modals/EditParcelItems.vue'
import InvalidationModal from '@/views/shipments/declarations/modals/components/InvalidationModal.vue'
import { searchQuery } from '@core/utils/filter'
import PostClearanceEditButton from '@/views/shipments/declarations/components/PostClearanceEditButton.vue'
import ConfirmModal from '@core/components/confirmation/confirmModal.vue'
import excelDownloadMixin from '@/mixins/excelDownloadMixin'
import moment from 'moment'

export default {
  mixins: [excelDownloadMixin],
  components: {
    ConfirmModal,
    PostClearanceEditButton,
    InvalidationModal,
    BIconArchive,
    BButtonGroup,
    BFormGroup,
    BFormRadioGroup,
    EditParcelItems,
    DownloadWarehouseExcelButton,
    BButton,
    BCardText,
    BCol,
    BRow,
    BTable,
    BSpinner,
    DownloadPdfButton,
    ViewParcelButton,
    Pagination,
    ParcelsTree,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['eventHub', 'shipment', 'filters', 'declarationType'],
  data() {
    return {
      loading: false,
      page: 1,
      perPage: 100,
      meta: {},
      selectedDeclaration: null,
      selectedParcel: null,
      showType: 'parcels',
      viewOptions: [
        { text: 'Parcels', value: 'parcels' },
        { text: 'Containers', value: 'containers' },
      ],
      containers: [],
      selectedAll: false,
      selectedContainers: [],
      selectedParcels: [],
      allParcelsData: [],
      excelDownloadUrl: `${process.env.VUE_APP_API_URL}/v1/companies/${this.shipment.company.id}/shipments/${this.shipment.id}/declarations/${this.declarationType}/cleared/excel`,
      messagesDownloadUrl: `${process.env.VUE_APP_API_URL}/v1/companies/${this.shipment.company.id}/shipments/${this.shipment.id}/messages/${this.declarationType}/cleared`,
      invoicesDownloadUrl: `${process.env.VUE_APP_API_URL}/v1/companies/${this.shipment.company.id}/shipments/${this.shipment.id}/invoices-download`,
      sortBy: null,
      isSortDirDesc: null,
      fields: [
        { key: 'actions', label: 'Actions' },
        { key: 'tracking_number', label: 'Tracking code', sortable: true },
        { key: 'container_code', label: 'Container', sortable: true },
        { key: 'lrn', label: 'LRN' },
        { key: 'mrn', label: 'MRN' },
        { key: 'updated_at', label: 'Declaration time' },
      ],
      declarations: [],
      editEnabled: false,
    }
  },
  computed: {
    excelFileName() {
      const timestamp = moment().format('YYYY-MM-DD-HH-mm-ss')
      return `cleared-declarations-list-${timestamp}.xlsx`
    },
    confirmPostClearanceMessage() {
      return 'Are you sure you want to proceed with the post-clearance amendment?'
    },
    confirmPostClearanceDescription() {
      return 'Note: When submitting a post-clearance amendment, bank account information will be transmitted. x7trade will automatically populate the next form with the bank account details from your Company settings. If necessary, you can update the default bank account there before proceeding.'
    },
    canEdit() {
      return this.editEnabled && this.$permissions().hasPermission('edit shipment')
    },
    canEditPostClearance() {
      return this.shipment.company.country.toLowerCase() === 'lv'
    },
    url() {
      let url = `/v1/companies/${this.shipment.company.id}/shipments/${this.shipment.id}/declarations/${this.declarationType}/cleared`
      url += `?page=${this.page}`
      url += `&per_page=${this.perPage}`
      url += `&listView=${this.showType}`
      return url
    },
    showInvalidation() {
      return this.$permissions().hasPermission('edit shipment')
        && (this.selectedAll || this.selectedContainers?.length || this.selectedParcels?.length)
        && ['FI', 'RO'].includes(this.shipment.entry_country)
    },
  },
  watch: {
    filters() {
      this.fetchData()
    },
    sortBy() {
      this.fetchData()
    },
    isSortDirDesc() {
      this.fetchData()
    },
    showType() {
      this.fetchData()
    },
  },
  created() {
    this.fetchData()
    this.eventHub.$on('general-declaration-update', this.fetchData)
    this.eventHub.$on('set-view-parcel', this.viewParcel)
    this.eventHub.$on('set-post-clearance-parcel', this.postClearanceParcel)
  },
  mounted() {
    this.eventHub.$on('pageChange', this.handlePageChange)
  },
  beforeDestroy() {
    this.eventHub.$off('general-declaration-update', this.fetchData)
    this.eventHub.$off('set-view-parcel', this.viewParcel)
    this.eventHub.$off('pageChange', this.handlePageChange)
    this.eventHub.$off('set-post-clearance-parcel', this.postClearanceParcel)
  },
  methods: {
    handlePageChange(newPage) {
      this.page = newPage
      this.fetchData()
    },
    selection(data) {
      this.selectedAll = data.selectedAll
      this.selectedContainers = data.selectedContainers
      this.selectedParcels = data.selectedParcels
    },
    rowClass(item) {
      return item?.parcel?.is_archived ? 'archived-row' : ''
    },
    viewParcel(declaration, parcel) {
      this.editEnabled = false
      this.selectedDeclaration = declaration
      this.selectedParcel = parcel
    },
    postClearanceParcel(declaration, parcel) {
      this.editEnabled = false
      this.selectedDeclaration = declaration
      this.selectedParcel = parcel
    },
    confirmPostClearance() {
      this.editEnabled = true
      this.$bvModal.hide('post-clearance-edit-modal')
      this.$bvModal.show('edit-parcel-items')
    },
    fetchData(activeTab) {
      if ((!activeTab || activeTab === 'cleared') && !this.loading) {
        this.loading = true
        this.$http.get(searchQuery(this.url, this.filters), {
          params: {
            sortBy: this.sortBy,
            sortDesc: this.isSortDirDesc,
          },
        })
          .then(response => {
            this.loading = false
            if (this.showType === 'parcels') {
              this.declarations = response.data.data
              this.meta = response.data.meta
              this.$props.eventHub.$emit('updateMeta', response.data.meta)
            } else {
              this.containers = response.data.data
            }
          })
      }
    },
    canDownloadClearanceMessages() {
      return this.shipment.company.config?.canDownloadClearanceMessages
    },
    declarationInvalidated() {
      this.$bvModal.hide('invalidate-declaration-modal')
    },
    resetSelects() {
      this.selectedAll = false
      this.selectedParcels = []
      this.selectedContainers = []
    },
  },
}
</script>

<style lang="scss">
.archived-row {
  background-color: #fafafa;
  color: #999;
  opacity: 0.8;
}
</style>
