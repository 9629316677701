<template>
  <b-card
    v-if="shipment && declaration"
    title="Customs declaration"
  >
    <b-row v-if="isPostClearanceLV">
      <b-col
        lg="3"
        md="4"
      >
        <b-form-group
          label="Bank account number"
          label-for="bank-account"
        >
          <validation-provider
            #default="{ errors }"
            name="Bank account number"
            rules="required|min:7|max:35"
          >
            <b-form-input
              id="bank-account"
              v-model="declaration.communication.bank_account"
              :disabled="disabled"
              class="mr-0 ml-0"
              :state="errors.length > 0 ? false:null"
            />
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        lg="6"
        md="6"
      >
        <label>
          Documents
        </label>
        <b-form-file
          v-if="!upload.isUploading"
          id="upload-btn"
          :ref="`fileHandle-${declaration.id}`"
          v-model="uploadFile"
          placeholder="Select a file"
          drop-placeholder="Drop file here..."
          :multiple="true"
          :state="declaration.communication.customs_attachments.length === 0 ? false:null"
          class="b-custom-control-xl"
          @input="submitFile()"
        />
        <b-progress
          v-if="upload.isUploading"
          ref="fileProgress"
          :value="upload.uploadProgress"
          max="100"
        />
      </b-col>
    </b-row>
    <b-row v-if="isPostClearanceLV && declaration.communication.files">
      <b-col
        lg="6"
        md="6"
      />
      <b-col
        lg="6"
        md="6"
      >
        <label>Documents</label><br>
        <div
          v-for="(file, idx) in declaration.communication.files"
          :key="idx"
          style="display: inline;margin-right: 10px;"
        >
          <b-link :href="`${fileBaseUrl}/files/${file.id}`">
            {{ file.name }}
          </b-link>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="declaration.previous_document_category"
        lg="3"
        md="4"
      >
        <label>Previous document category</label>
        <v-select
          v-model="declaration.previous_document_category"
          :options="previous_document_categories"
          :clearable="false"
          :disabled="disabled"
          :reduce="item => item.value"
          label="text"
          input-id="previous-document-category"
        />
      </b-col>
      <b-col
        lg="3"
        md="4"
      >
        <label>Previous document type</label>
        <v-select
          id="previous-document-type"
          v-model="declaration.previous_document_type"
          :options="previousDocumentTypes"
          :clearable="false"
          :disabled="disabled"
        />
      </b-col>
      <b-col
        lg="3"
        md="4"
      >
        <label>Previous document reference</label>
        <b-form-input
          id="previous-document-reference"
          v-model="declaration.previous_document_reference"
          :disabled="disabled"
          class="mr-0 ml-0"
        />
      </b-col>
      <b-col
        lg="3"
        md="4"
      >
        <b-form-group
          label="Choose warehouse"
          label-for="vue-select"
        >
          <validation-provider
            #default="{ errors }"
            name="Warehouse"
            :rules="'required'"
          >
            <b-form-select
              id="warehouse"
              v-model="declaration.warehouse_id"
              :options="warehouses"
              :state="errors.length > 0 ? false : null"
              :disabled="disabled"
            />
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        v-if="declaration.declaration_type === 'H1'"
        lg="3"
        md="4"
      >
        <b-form-group
          label="Inland mode of transport"
          label-for="inland_mode_of_transport"
        >
          <validation-provider
            #default="{ errors }"
            name="Inland mode of transport"
            :rules="'required'"
          >
            <b-form-select
              id="inland_mode_of_transport"
              v-model="declaration.inland_mode_of_transport"
              :options="transport_types"
              :state="errors.length > 0 ? false : null"
              :disabled="disabled"
            />
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        v-if="declaration.declaration_type === 'H1'"
        lg="3"
        md="4"
      >
        <b-form-group
          label="Mode of transport at border"
          label-for="mode_of_transport_at_border"
        >
          <validation-provider
            #default="{ errors }"
            name="Mode of transport at border"
            :rules="'required'"
          >
            <b-form-select
              id="mode_of_transport_at_border"
              v-model="declaration.mode_of_transport_at_border"
              :options="transport_types"
              :state="errors.length > 0 ? false : null"
              :disabled="disabled"
            />
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        v-if="declaration.declaration_type === 'H1'"
        lg="3"
        md="4"
      >
        <label>Transport registration number</label>
        <b-form-input
          id="transport-registration-number"
          v-model="declaration.transport_registration_number"
          :disabled="disabled"
          class="mr-0 ml-0"
        />
      </b-col>
    </b-row>
    <b-row v-if="declaration.status_info && declaration.status_info['errors']">
      <b-col>
        <label>Declaration status</label>
        <ul class="pl-2">
          <li
            v-for="(error, index) in declaration.status_info['errors']"
            :key="index"
          >
            {{ error.message || 'error message is undefined' }}
          </li>
        </ul>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BCard, BFormGroup, BFormSelect, BProgress, BFormFile, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import declarationDataTypeCache from '@/views/shipments/declarations/components/classes/declarationDataTypeCache'
import { ValidationProvider } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BLink,
    BFormFile,
    BProgress,
    BFormSelect,
    ValidationProvider,
    BFormGroup,
    BCard,
    vSelect,
    BRow,
    BCol,
    BFormInput,
  },
  props: {
    shipment: {
      type: Object,
      required: true,
    },
    declaration: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      previousDocumentTypes: [],
      previous_document_categories: [
        { value: 'X', text: 'Summary Declaration' },
        { value: 'Y', text: 'Initial Declaration' },
        { value: 'Z', text: 'Previous Document' },
      ],
      transport_types: this.$classifiers().getOptions('transport_types'),
      warehouses: [{ value: '', text: '----select----' }],
      upload: {
        isUploading: false,
        uploadProgress: 0,
      },
      uploadFile: null,
      fileBaseUrl: `${process.env.VUE_APP_API_URL}/v1/companies/${this.shipment.company_id}/shipments/${this.shipment.id}/declarations/${this.declaration.id}`,
    }
  },
  created() {
    this.fetchPreviousDocTypes()
    this.$http.get(`/v1/companies/${this.shipment.company_id}/warehouses`)
      .then(response => {
        const warehouses = response.data.data

        this.warehouses = this.warehouses.concat(warehouses.map(warehouse => ({ value: warehouse.id, text: warehouse.name })))
      })
    if (this.isPostClearanceLV) {
      this.declaration.communication = {
        bank_account: this.shipment.company.bank_account,
        customs_attachments: [],
      }
    }
  },
  computed: {
    isPostClearanceLV() {
      return !this.disabled
        && this.declaration
        && this.declaration.customs_country === 'LV'
        && ['cleared', 'cleared_manually'].includes(this.declaration.status)
    },
  },
  methods: {
    fetchPreviousDocTypes() {
      if (this.shipment) {
        const cachedData = declarationDataTypeCache().getCachedDataTypes(this.shipment.entry_country)
        if (cachedData === null) {
          declarationDataTypeCache().getDataTypes(this.shipment.entry_country, this)
            .then(response => {
              response.data.data.forEach(type => {
                this.previousDocumentTypes.push(type?.type)
              })
              declarationDataTypeCache().cacheDataTypes(this.previousDocumentTypes)
            })
        } else {
          this.previousDocumentTypes = cachedData
        }
      }
    },
    submitFile() {
      const formData = new FormData()
      this.uploadFile.forEach((file, i) => {
        formData.append(`files[${i}]`, file)
      })
      this.upload.isUploading = true
      this.upload.uploadProgress = 0
      this.$http.post(`/v1/companies/${this.shipment.company_id}/shipments/${this.shipment.id}/declarations/${this.declaration.id}/files`,
        formData,
        {
          onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            this.upload.uploadProgress = percentCompleted

            return percentCompleted
          }.bind(this),
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          this.upload.isUploading = false
          if (response.data.customsAttachments) {
            this.declaration.communication.customs_attachments = response.data.customsAttachments
          }
          this.declaration.communication.files = response.data.files
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File successfully uploaded',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.upload.isUploading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to upload file',
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
