<template>
  <div>
    <confirm-modal
      v-if="showExcelDownloadModal"
      id="excel-download-modal"
      title="Information"
      :confirmation="confirmModalMessage"
      ok-button="Close"
      close-button=""
      @submit="showExcelDownloadModal = false"
    />
    <edit-parcel-items
      v-if="selectedDeclaration || selectedParcel"
      id="edit-parcel-items"
      :parcel="selectedParcel"
      :event-hub="eventHub"
      :declaration="selectedDeclaration"
      :shipment="selectedParcel ? selectedParcel.shipment : null"
      :readonly="!canEdit"
    />
    <b-row>
      <b-col
        class="mt-2"
        cols="9"
      >
        <b-card-text>
          List of cancelled parcel declarations
        </b-card-text>
      </b-col>
      <b-col class="text-right">
        <b-button
          variant="outline-success"
          class="m-1"
          @click="handleExcelDownload"
        >
          Download as Excel
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="overflow-x-scroll">
          <b-table
            :items="declarations"
            responsive
            :fields="fields"
            :busy="loading"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            no-local-sorting
            show-empty
            empty-text="No matching records found"
            class="mb-0"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading...</strong>
              </div>
            </template>

            <template #head()="data">
              <span class="text-nowrap">
                {{ data.label }}
              </span>
            </template>

            <template #cell(actions)="data">
              <view-parcel-button
                :declaration="data.item"
                :parcel="data.item.parcel"
                :event-hub="eventHub"
              />
            </template>

            <template #cell(tracking_number)="data">
              {{ data.item.parcel.tracking_number }}
            </template>

            <template #cell(container_code)="data">
              {{ data.item.parcel.container_code }}
            </template>

            <template #cell(updated_at)="data">
              {{ data.item.updated_at | formatDate }}
            </template>

          </b-table>
        </div>
        <pagination
          v-show="!loading"
          :event-hub="eventHub"
          :per-page="perPage"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCardText,
  BCol,
  BRow,
  BTable,
  BSpinner,
  BButton,
} from 'bootstrap-vue'
import ViewParcelButton from '@/views/shipments/declarations/components/ViewParcelButton.vue'
import Pagination from '@/layouts/components/Pagination/Pagination.vue'
import EditParcelItems from '@/views/shipments/declarations/modals/EditParcelItems.vue'
import Vue from 'vue'
import { searchQuery } from '@core/utils/filter'
import ConfirmModal from '@core/components/confirmation/confirmModal.vue'
import excelDownloadMixin from '@/mixins/excelDownloadMixin'
import moment from 'moment'

export default {
  mixins: [excelDownloadMixin],
  components: {
    ConfirmModal,
    BButton,
    EditParcelItems,
    ViewParcelButton,
    BCardText,
    BCol,
    BRow,
    BTable,
    BSpinner,
    Pagination,
  },
  props: {
    eventHub: {
      default: () => new Vue(),
    },
    shipment: {
      type: Object,
      required: true,
    },
    declarationType: {
      type: String,
      default: 'h7',
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      page: 1,
      perPage: 100,
      selectedDeclaration: null,
      selectedParcel: null,
      sortBy: null,
      isSortDirDesc: null,
      excelDownloadUrl: `${process.env.VUE_APP_API_URL}/v1/companies/${this.shipment.company.id}/shipments/${this.shipment.id}/declarations/${this.declarationType}/cancelled/excel`,
      fields: [
        { key: 'actions', label: 'Actions' },
        { key: 'tracking_number', label: 'Tracking code', sortable: true },
        { key: 'container_code', label: 'Container', sortable: true },
        { key: 'lrn', label: 'LRN' },
        { key: 'mrn', label: 'MRN' },
        { key: 'updated_at', label: 'Declaration time' },
      ],
      declarations: [],
    }
  },
  computed: {
    excelFileName() {
      const timestamp = moment().format('YYYY-MM-DD-HH-mm-ss')
      return `cancelled-declarations-list-${timestamp}.xlsx`
    },
    canEdit() {
      return this.$permissions().hasPermission('edit shipment')
    },
    url() {
      let url = `/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipment.id}/declarations/cancelled`
      url += `?page=${this.page}`
      url += `&per_page=${this.perPage}`
      return url
    },
  },
  watch: {
    filters() {
      this.fetchData()
    },
    sortBy() {
      this.fetchData()
    },
    isSortDirDesc() {
      this.fetchData()
    },
  },
  created() {
    this.eventHub.$on('update-parcel-items', this.handleUpdateParcelItems)
    this.eventHub.$on('general-declaration-update', this.fetchData)
    this.eventHub.$on('set-view-parcel', this.viewParcel)
    this.fetchData()
  },
  mounted() {
    this.eventHub.$on('pageChange', this.handlePageChange)
  },
  beforeDestroy() {
    this.eventHub.$off('update-parcel-items', this.handleUpdateParcelItems)
    this.eventHub.$off('general-declaration-update', this.fetchData)
    this.eventHub.$off('set-view-parcel', this.viewParcel)
    this.eventHub.$off('pageChange', this.handlePageChange)
  },
  methods: {
    handleUpdateParcelItems() {
      this.selectedDeclaration = null
      this.selectedParcel = null
      this.fetchData()
      this.eventHub.$emit('refresh-statistics')
    },
    handlePageChange(newPage) {
      this.page = newPage
      this.fetchData()
    },
    viewParcel(declaration, parcel) {
      this.selectedDeclaration = declaration
      this.selectedParcel = parcel
    },
    fetchData(activeTab) {
      if ((!activeTab || activeTab === 'cancelled') && !this.loading) {
        this.loading = true
        this.$http.get(searchQuery(this.url, this.filters), {
          params: {
            sortBy: this.sortBy,
            sortDesc: this.isSortDirDesc,
          },
        })
          .then(response => {
            this.loading = false
            this.declarations = response.data.data
            this.$props.eventHub.$emit('updateMeta', response.data.meta)
          })
      }
    },
  },
}
</script>
